<template>
  <div>
    <slot></slot>
    <ul class="tabs__header standard-tab-p">
      <li
        v-for="(tab, index) in tabs"
        :key="tab.title"
        @click="selectTab(index)"
      >
        <div
          class="standard-tab"
          :class="{ tab_active: index == selectedIndex }"
        >
          <img :src="imgs[index]" loading="lazy" />
          <h4>{{ tab.title }}</h4>
          <div class="lp-tab-text">{{ $t('mobileEmployeeDesc' + index) }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import cloudinary from '@/constants/cloudinary';
export default {
  data() {
    return {
      selectedIndex: 0,
      tabs: [],
      imgs: [
        cloudinary.BUSINESS_CD_FREE,
        cloudinary.BUSINESS_HEALTH_STANDARD,
        cloudinary.BUSINESS_STATUSCLEAR,
      ],
    };
  },
  created() {
    this.tabs = this.$children;
  },
  mounted() {
    this.selectTab(0);
    this.tabAutoSlide();
  },
  methods: {
    selectTab(i) {
      this.selectedIndex = i;
      // loop over all the tabs
      this.tabs.forEach((tab, index) => {
        tab.isActive = index === i;
      });
    },
    tabAutoSlide() {
      let self = this;
      setInterval(function() {
        if (self.selectedIndex === 0) {
          self.selectTab(self.selectedIndex + 1);
        } else if (self.selectedIndex === 1) {
          self.selectTab(self.selectedIndex + 1);
        } else {
          self.selectTab(0);
        }
      }, 4000);
    },
  },
};
</script>

<style scoped lang="scss">
ul.standard-tab-p {
  padding-top: 2.1875rem;
  padding-left: 1.25rem;
  li {
    list-style: none;
  }
  li::before {
    content: none;
  }
  .standard-tab {
    display: block;
    width: 30rem;
    height: 12.5rem;
    padding: 1.5rem;
    border-radius: 1.5rem;
    margin-top: 1.875rem;
    margin-left: 1.875rem;
    h4 {
      margin-top: 0.625rem;
      margin-bottom: 0.625rem;
      color: #161616;
      font-size: 1.375rem;
      line-height: 1.2em;
      font-weight: 700;
    }
    .lp-tab-text {
      color: #505050;
      font-size: 1.375rem;
      line-height: 1.2em;
    }
  }
  .standard-tab:hover,
  .tab_active {
    background-color: #fff;
    box-shadow: 17px 17px 100px 48px rgb(56 87 166 / 8%);
    cursor: pointer;
  }
}
</style>
